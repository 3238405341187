
import RcObject from "@/domain/common/RcObject.js";

import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";

export default class CheckInListFilter extends RcObject {
  
  static Fields = {
    PAGINATION: 'pagination',
    CREATED_DATE_RANGE: 'createDateRange',
    COMPANY: 'Company',
    EMPLOYEES: EmployeeMap.MODEL_NAME,
  }
  
  constructor() {
    super({});
  }
  
  withPagination(size, number) {
    this.put(CheckInListFilter.Fields.PAGINATION, { size, number });
    return this;
  }
  
  withDateRange(range) {
    this.put(CheckInListFilter.Fields.CREATED_DATE_RANGE, range);
    return this;
  }

  withCompany(company) {
    this.put(CheckInListFilter.Fields.COMPANY, company);
    return this;
  }
  
  withEmployees(employees) {
    if (!employees) {
      return this;
    }
    this.put(CheckInListFilter.Fields.EMPLOYEES, employees.data);
    return this;
  }
  
  done() {
    //
  }
}