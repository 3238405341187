<template>
  <Box cmpt="ResultDetails">
    <ErrorRow :error="error" />

    <!-- Vehicle -->
    <LabelValueRow>
      <template #label>Name</template>
      <template #value>{{ Contents.decode(vehicle.name()) }} </template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>Plate</template>
      <template #value>{{ Contents.decode(vehicle.plate()) }}</template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>Jurisdiction</template>
      <template #value>{{ Contents.decode(vehicle.jurisdiction()) }}</template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>Odometer</template>
      <template #value>{{ initial.odometer() }}</template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>Schedule</template>
      <template #value>{{ Contents.decode(initial.schedule().find().name()) }}</template>
    </LabelValueRow>

    <LabelValueRow v-if="initial.isAttached() && initial.vehicle().find().isTrailer()">
      <template #label>Attached</template>
      <template #value>Yes</template>
    </LabelValueRow>

    <LabelValueRow v-if="initial.isAttached() && initial.vehicle().find().isTrailer()">
      <template #label>Attached On</template>
      <template #value>{{ initial.attachedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }} </template>
    </LabelValueRow>

    <LabelValueRow v-if="initial.isDetached() && initial.vehicle().find().isTrailer()">
      <template #label>Attached</template>
      <template #value>No</template>
    </LabelValueRow>

    <LabelValueRow v-if="initial.isDetached() && initial.vehicle().find().isTrailer()">
      <template #label>Detached On</template>
      <template #value>{{ initial.detachedDateV2().displayAs_YYYY_MM_DD_HH_MM_SS_A() }} </template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label></template>
      <template #value></template>
    </LabelValueRow>
    <!-- Vehicle Inspection -->

    <Row>
      <Box>

        <Row>
          <Column :width="1" class="rc-font-bold rc-text-label">
            <Box>
              <Row>
                <Column :width="15" class="rc-text-centre">In</Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1" class="rc-font-bold rc-text-label">
            <Box>
              <Row>
                <Column :width="15" class="rc-text-centre">En</Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1" class="rc-font-bold rc-text-label">
            <Box>
              <Row>
                <Column :width="15" class="rc-text-centre">Re</Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1" class="rc-font-bold rc-text-label">
            <Box>
              <Row>
                <Column :width="15" class="rc-text-centre">#</Column>
              </Row>
            </Box>
          </Column>
          <Column :width="11" class="rc-font-bold rc-text-label">
            <Box>
              <Row>
                <Column :width="15">Systems and Components</Column>
              </Row>
            </Box>
          </Column>
        </Row>

        <Row v-for="(question, questionIndex) in questions" :key="questionIndex"
          :class="isEven(questionIndex) ? 'rowEven' : 'rowOdd'">
          <Column :width="1">
            <!-- Initial -->
            <Box v-if="initial.answers().find().majorsFor(question)"></Box>
            <Box v-else-if="initial.answers().find().minorsFor(question)"></Box>
            <Box v-else>
              <Row>
                <Column :width="15" v-if="initial.answers().find().naFor(question)">
                  <img src="@/assets/2019/chek_na.png" width="18px" />
                </Column>
                <Column :width="15" v-else>
                  <img src="@/assets/2019/none_chek.png" width="18px" />
                </Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1">
            <!-- Enroute -->
            <Box v-if="majorsFor(question)"></Box>
            <Box v-else-if="enroute.answers().find().minorsFor(question)"></Box>
            <Box v-else>
              <Row v-if="!enroute.isNew()">
                <Column :width="15" v-if="enroute.answers().find().naFor(question)">
                  <img src="@/assets/2019/chek_na.png" width="18px" />
                </Column>
                <Column :width="15" v-else-if="enroute.answers().find().okFor(question)">
                  <img src="@/assets/2019/none_chek.png" width="18px" />
                </Column>
              </Row>
            </Box>
          </Column>
          <Column :width="1">
            <!-- Repairs -->
          </Column>
          <Column :width="1" class="rc-text-value rc-text-small">
            {{ question.part() }}
          </Column>
          <Column :width="11" class="rc-text-value rc-text-small">
            {{ question.value() }}
          </Column>

          <Column :width="15">
            <ResultDetailOptionRows :resultId="initial.id()" :questionId="question.id()" :questionIndex="questionIndex"
              :majors="false" :forRepair="forRepair" v-on:repairAction="repairAction" />

            <ResultDetailOptionRows :resultId="enroute.id()" :questionId="question.id()" :questionIndex="questionIndex"
              :majors="false" :initial="false" :forRepair="forRepair" v-on:repairAction="repairAction" />

            <ResultDetailOptionRows :resultId="initial.id()" :questionId="question.id()" :questionIndex="questionIndex"
              :majors="true" :forRepair="forRepair" v-on:repairAction="repairAction" />

            <ResultDetailOptionRows :resultId="enroute.id()" :questionId="question.id()" :questionIndex="questionIndex"
              :majors="true" :initial="false" :forRepair="forRepair" v-on:repairAction="repairAction" />
          </Column>
        </Row>

        <!-- end of question loop -->
        <Row class="rc-text-left">
          <div class="rc-box-col-15 rc-input-good rc-text-left" v-if="initial.isDefectTypeGood()">
            {{ toInitialDeclaration() }}
          </div>
          <div class="rc-box-col-15 rc-input-minor rc-text-left" v-else-if="initial.isDefectTypeMinor()">
            {{ toInitialDeclaration() }}
          </div>
          <div class="rc-box-col-15 rc-input-major rc-text-left" v-else-if="initial.isDefectTypeMajor()">
            {{ toInitialDeclaration() }}
          </div>
        </Row>
        <Row v-if="!enroute.isNew()">
          <div class="rc-box-col-15 rc-input-good rc-text-left" v-if="enroute.isDefectTypeGood()">
            {{ toEnrouteDeclaration() }}
          </div>
          <div class="rc-box-col-15 rc-input-minor rc-text-left" v-else-if="enroute.isDefectTypeMinor()">
            {{ toEnrouteDeclaration() }}
          </div>
          <div class="rc-box-col-15 rc-input-major rc-text-left" v-else-if="enroute.isDefectTypeMajor()">
            {{ toEnrouteDeclaration() }}
          </div>
        </Row>

      </Box>
    </Row>

  </Box>
</template>

<script>
import { mapGetters } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import Catalog from '@/domain/session/Catalog.js';

import ErrorRow from '@/components/row/ErrorRow.vue'

import Utils from '@/views/portal/inspections/Utils.js'
import Inspection from '@/domain/model/inspection/Inspection.js';
import Vehicle from '@/domain/model/vehicle/Vehicle.js';
import Result from '@/domain/model/inspection/result/Result.js';

import ResultDetailOptionRows from "./ResultDetailOptionRows.vue";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";

export default {
  name: 'portal-result-details',
  components: {
    Box, Row, Column,
    LabelValueRow,
    ErrorRow,
    ResultDetailOptionRows,
  },
  data() {
    return {
      event: {},
      userData: {},
      error: null,
      Contents: ContentUtils,
      Strings: StringUtils,
      Catalog: Catalog,
    }
  },
  props: {
    initialResult: {},
    enrouteResult: {},
    forVehicle: { type: Boolean, default: true },
    inspectionData: {},
    forRepair: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
      'vehicles_found',
      'AnswerStorage',
      'repairs_found',
      'inspections_found',
      'employees_found',
      'companys_found',
      'questions_found',
      'options_found',
      'results_found',
      'types_found',
      'users_store',
      'makers_found',
      'vehicles_found',
      'categorys_found',
      'schedules_found',
      'signatures_store',
      'auth_client',
      'auth_user',
      'savevehicle_event',
      'auth_connected',
      'domain',
    ]),
    inspection: function () {
      if (this.domain) {
        return new Inspection(this.domain, this.inspectionData);
      }
      return new Inspection(this.domain, {});
    },
    vehicle: function () {
      var vehicleRef = this.initialResult[ConstUtils.FIELDS.VEHICLE]
      var data = null;
      if (vehicleRef) {
        data = this.vehicles_found.map[vehicleRef[ConstUtils.FIELDS.ID]]
      }
      return new Vehicle(this.domain, data);
    },
    questions: function () {
      var i = this.initial;
      var schedule = i.schedule().find();
      var qs = schedule.questions().find();
      return qs.sort();
    },
    initial: function () {
      return new Result(this.domain, this.initialResult);
    },
    enroute: function () {
      return new Result(this.domain, this.enrouteResult);
    },
  },
  mounted: function () {
    if (!this.auth_connected) {
      this.$router.replace(
        { name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    }
  },
  watch: {
    // setup change listeners
  },
  methods: {
    // methods
    isEmpty: function (value) {
      return StringUtils.isEmpty(value)
    },
    majorsFor: function(question) {
      var answers = this.enroute.answers().find();
      var result = answers.majorsFor(question);
      return result;
    },
    findScheduleQuestions: function (result) {
      var questions = []
      if (result) {
        var schRef = result.VehicleSchedule
        if (schRef) {
          var schId = schRef[ConstUtils.FIELDS.ID]
          var schedule = this.schedules_found.map[schId]
          if (schedule) {
            var map = schedule.VehicleScheduleQuestionMap
            var keys = Object.keys(map)
            for (var i = 0; i < keys.length; i++) {
              var question = this.questions_found.map[keys[i]]
              if (question) {
                questions.push(question)
              }
            }
          }
        }
      }
      return questions.sort(Utils.SortQuestionByPart)
    },

    displayVehicleName: function () {
      return ContentUtils.unescape(this.vehicle.name) + ' ' + this.toVehicleSummary()
    },
    toVehicleSummary: function () {
      if (this.vehicle && this.vehicle.summary) {
        return '(' + ContentUtils.unescape(this.vehicle.summary) + ')'
      }
      return ''
    },
    toVehicleOdometerType: function () {
      return this.vehicle.odometerType
    },
    toVehiclePlate: function () {
      return this.vehicle.plate
    },
    toVehicleProvince: function () {
      return this.vehicle.jurisdiction
    },
    displaySchedule: function (result) {
      var ref = result.VehicleSchedule
      if (ref) {
        var schedule = this.schedules_found.map[ref[ConstUtils.FIELDS.ID]]
        var name = schedule.name
        var last = name.lastIndexOf('(')
        if (last > 0) {
          name = name.substring(0, last)
        }
        return name
      }
      return ''
    },

    toInitialResult: function (question) {
      return question.part
    },
    toEnrouteResult: function (question) {
      return question.part
    },
    toFixResult: function (question) {
      return question.part
    },
    toInitialDeclaration() {
      var defectType = this.initialResult.defectType
      return 'Initial: ' + this.toDeclaration(defectType)
    },

    toEnrouteDeclaration() {
      if (!this.enrouteResult || Object.keys(this.enrouteResult).length == 0) {
        return ''
      }
      var map = this.enrouteResult.VehicleInspectionAnswerMap;
      if (map && Object.keys(map).length > 0) {
        var defectType = this.enrouteResult.defectType
        return 'Enroute: ' + this.toDeclaration(defectType)
      }
      return '';
    },

    toDeclaration(defectType) {
      if (defectType === 'None') {
        return 'No defects have been found on the vehicle'
      }
      if (defectType === 'Minor') {
        return 'The minor defects found do not need to be corrected for the safe operation of this vehicle'
      }
      return 'The major defects found MUST be corrected for the safe operation of this vehicle'
    },
    toInitialVehicleMinors: function (question) {
      var results = this.initialResult
      return this._options(question, ConstUtils.QUESTIONS.VALUES.MINOR, results)
    },
    toInitialVehicleMajors: function (question) {
      var results = this.initialResult
      return this._options(question, ConstUtils.QUESTIONS.VALUES.MAJOR, results)
    },
    hasEnrouteInspection: function () {
      var results = this.enrouteResult
      return results != null
    },
    toEnrouteVehicleMinors: function (question) {
      var results = this.enrouteResult
      return this._options(question, ConstUtils.QUESTIONS.VALUES.MINOR, results)
    },
    toEnrouteVehicleMajors: function (question) {
      var results = this.enrouteResult
      return this._options(question, ConstUtils.QUESTIONS.VALUES.MAJOR, results)
    },
    hasInitialNaOption: function (question) {
      if (question) {
        var result = this.initialResult
        var answers = this._options(question, ConstUtils.QUESTIONS.VALUES.OTHER, result)
        if (answers.length > 0) {
          if (answers[0].option.value === ConstUtils.QUESTIONS.VALUES.NA) {
            return true
          }
        }
      }
      return false
    },
    hasEnrouteNaOption: function (question) {
      if (question) {
        var result = this.enrouteResult
        var answers = this._options(question, ConstUtils.QUESTIONS.VALUES.OTHER, result)
        if (answers.length > 0) {
          if (answers[0].option.value === ConstUtils.QUESTIONS.VALUES.NA) {
            return true
          }
        }
      }
      return false
    },

    hasEnrouteOkOption: function (question) {
      if (question) {
        var result = this.enrouteResult
        var answers = this._options(question, ConstUtils.QUESTIONS.VALUES.OTHER, result)
        if (answers.length > 0) {
          if (answers[0].option.value === ConstUtils.QUESTIONS.VALUES.OK) {
            return true
          }
        }
      }
      return false
    },
    _options: function (question, category, results) {
      if (!results || !results.VehicleInspectionAnswerMap) {
        return []
      }

      var keys = Object.keys(results.VehicleInspectionAnswerMap);
      var answers = [];
      var after = [];
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i]
        var answerRef = results.VehicleInspectionAnswerMap[key]
        var answer = this.AnswerStorage.map[answerRef[ConstUtils.FIELDS.ID]]
        var answerQuestionId = answer.VehicleScheduleQuestion[ConstUtils.FIELDS.ID]
        if (answerQuestionId == question[ConstUtils.FIELDS.ID]) {
          var options = answer.VehicleScheduleQuestionOptionMap
          if (!options) {
            continue
          }
          var optionIds = Object.keys(options)

          for (var j = 0; j < optionIds.length; j++) {
            var pkg = {
              option: null,
              repairs: answer.VehicleInspectionRepairMap,
              repaired: false,
            }

            var repairKeys = Object.keys(pkg.repairs)
            for (var repairKeyIndex = 0; repairKeyIndex < repairKeys.length; repairKeyIndex++) {
              var repairKey = repairKeys[repairKeyIndex]
              var repair = this.repairs_found.map[repairKey];
              if (repair.VehicleScheduleQuestionOption[ConstUtils.FIELDS.ID]
                === pkg.option[ConstUtils.FIELDS.ID]) {
                pkg.repaired = true;
              }
            }

            var optionId = optionIds[j]
            var option = this.options_found.map[optionId]
            if (option && option.category == category) {
              pkg.option = option
              if (option.type == 'typeInputText') {
                if (option.category == 'major') {
                  pkg['notes'] = answer.FreeFormMajor
                } else {
                  pkg['notes'] = answer.FreeFormMinor
                }
                after.push(pkg);
              } else {
                answers.push(pkg)
              }
            }
          }
        }
      }
      for (var k = 0; k < after.length; k++) {
        var remark = after[k];
        answers.push(remark);
      }
      return answers
    },
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    repairAction: function (pkg) {
      if (pkg) {
        this.$emit('repairAction', pkg);
      }
    }
  },
}
</script>