<template>
  <div>
    
    <div v-if="timesheet.isNew()" :class="rowClass">
      <div class="row">
        <div v-if="isLoadingTimesheets" class="rc-box-col-15">
          <img src="@/assets/2022/800x800transparent.png" width="24px" />
        </div>
        <div v-else class="rc-box-col-15">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Timesheet.Status.Missing.value() }}</div>
          <img 
            v-if="isDriverManagerOwner()"
            src="@/assets/2022/HOS-Missing3.png"
            width="24px" />
          <img
            v-else
            src="@/assets/2022/800x800transparent.png"
            width="24px"
            />
        </div>

      </div>
    </div>
    
    <div v-else :class="rowClass">
      
      <div class="row clickable" >
        <div v-if="timesheet.isStatusCompleteOffDay()" class="rc-box-col-15" v-on:click="details">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Timesheet.Status.OffDay.value() }}</div>
          <img src="@/assets/2022/HOS-OffDuty3.png" width="24px" />
        </div>
        <div v-else-if="timesheet.isStatusComplete()" class="rc-box-col-15" v-on:click="details">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Timesheet.Status.Complete.value() }}</div>
          <img src="@/assets/2022/HOS-Complete2.png" width="24px" />
        </div>
        <div v-else-if="timesheet.isStatusInProgress()" class="rc-box-col-15" v-on:click="details">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Timesheet.Status.InProgress.value() }}</div>
          <img src="@/assets/2022/HOS-InProgress.png" width="24px" />
        </div>
        <div v-else class="rc-box-col-15">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Timesheet.Status.Incomplete.value() }}</div>
          <img src="@/assets/2022/HOS-Incomplete2.png" width="24px" v-on:click="details" />
        </div>
      </div>

      <div class="row">
        <div class="rc-box-col-15">
        </div>
      </div>
      
    </div>
    
    <ConfirmReminder 
      v-if="reminding" 
      v-on:closeRejected="closeRejected" 
      v-on:closeConfirmed="closeConfirmed" 
      :timesheetData="timesheet.data" 
    />
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog from "@/domain/session/Catalog.js";
import MC      from "@/domain/session/MC.js";

import ConstUtils      from '@/utils/ConstUtils.js';
import StringUtils      from '@/utils/StringUtils.js';

import Timesheet     from "@/domain/model/timesheet/Timesheet.js";
import RoleMap   from "@/domain/model/role/RoleMap.js";

//import Button         from '@/components/button/Button.vue';
import ConfirmReminder from './ConfirmReminder.vue';

//import SpacerRow       from '@/components/row/SpacerRow.vue';

export default {
  name: 'portal-compliance-table-cell-timesheet',
  components: {
//    Button,
    ConfirmReminder,
  },
  props: {
    timesheetData: {type: Object, default: null},
    rowIndex: {type: Number, default: 0},
    columnIndex: {type: Number, default: 0},
    stillLoadingTimesheets: { type: Boolean, default: true },
    showLabels: { type: Boolean, default: false },
  },
  data() {
    return {
      C: new Catelog(),
      MC: new MC(),
      timesheet: new Timesheet(this.domain),
      roles: new RoleMap(this.domain),
      reminding: false,
      isSending: false,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    rowClass: function() {
      if (StringUtils.isEven(this.rowIndex)) {
        return "rc-background-light-thin container";
      }
      return "rc-background-dark-thin container";
    },
    isLoadingTimesheets: function() {
      return this.stillLoadingTimesheets;
    },
    reminderLabel: function() {
      if (this.columnIndex <= 5) {
        return "Nudge";
      }
      if (this.columnIndex > 5 && this.columnIndex < 11) {
        return "Poke";
      }
      return "Slap";
    },
  },
  watch: {
    timesheetData() {
      this.timesheet = new Timesheet(this.domain, this.timesheetData);
      this.roles = this.timesheet.employee().find().roles().find();
    }
  },
  mounted: function () {
    this.timesheet = new Timesheet(this.domain, this.timesheetData);
    this.roles = this.timesheet.employee().find().roles().find();
  },
  methods: {
    details: function() {
      var params = {
          id: this.timesheet.id(),
        }
        this.$router.push({
          name: ConstUtils.ROUTES.TIMESHEET.DETAILS,
          params: params,
        })
    },
    remind: function() {
      this.reminding = true;
    },
    closeRejected: function() {
      this.reminding = false;
    },
    closeConfirmed: function() {
      this.reminding = false;
      this.isSending = true;
      var map = this.timesheet.map();
      var event = this.domain.events().timesheets().reminder(map);
      event.send(this.timesheetReminderListener);
    },
    timesheetReminderListener: function(event) {
      this.isSending = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        //var payload = event.payload();
      }
    },
    isDriverManagerOwner: function() {
      if (this.roles.isDriver()) {
        return true;
      }
      if (this.roles.isOwner()) {
        return true;
      }
      if (this.roles.isManager()) {
        return true;
      }
      return false;
    }
  },
}
</script>