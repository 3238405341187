<template>
  <div>
    <ErrorRow :error="error" />

    <TitleRow align="left">Repairs</TitleRow>

    <Row v-for="(result) in resultList" :key="result.data['@rid']" >
      <Column :width="15" :showBorder="true">
        <Box>
          <LabelValueRow>
            <template #label>Date</template>
            <template #value>{{ result.repairDateDisplay() }}</template>
          </LabelValueRow>
          
          <LabelValueRow>
            <template #label>Repaired Items</template>
            <template #value>
              <div v-for="(repair, index) in result.repairList()" :key="repair.data['@rid']">
                {{ repair.option().find().display(index) }} {{ repair.remarks() }}
              </div>
            </template>
          </LabelValueRow>
        
          <LabelValueRow>
            <template #label>Work Orders</template>
            <template #value>{{ result.workOrders() }}</template>
          </LabelValueRow>
          
          <LabelValueRow>
            <template #label>Name</template>
            <template #value>{{ result.employee().find().user().find().fullName() }}</template>
          </LabelValueRow>
          
          <LabelValueRow>
            <template #label>Signature</template>
            <template #value>
              <img width="200px" :src="result.signature().find().image()" />
            </template>
          </LabelValueRow>
          </Box>
      </Column>
    </Row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorRow from '@/components/row/ErrorRow.vue'

import Inspection from '@/domain/model/inspection/Inspection.js';

import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import LabelValueRow from '@/portals/shared/library/labelvalue/LabelValueRow.vue';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

export default {
  name: 'portal-repair-result-details',
  components: {
    ErrorRow,
    TitleRow, LabelValueRow,
    Box,Row,Column,
  },
  data() {
    return {
      error: null,
    }
  },
  props: {
    inspectionData: {},
  },
  computed: {
    ...mapGetters([
                   'repairResults_store',
                   'repairs_found',
                   'users_store',
                   'options_found',
                   'employees_found',
                   'signatures_store',
                   'domain',
                   ]),
    inspection: function() {
      if (this.inspectionData) {
        return new Inspection(this.domain, this.inspectionData);
      }
      return new Inspection(this.domain);
    },
    results: function() {
      return this.inspection.repairResults().find();
    },
    resultList: function() {
      return this.results.list();
    }
  },
  mounted: function () {
  },
  watch: {
  },
  methods: {
  },
}
</script>