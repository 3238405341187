<template>
  <div>
    <ErrorRow :error="error" />

    <div v-for="comment in comments" v-bind:key="comment.id()" >
      <CommentLayout :commentData="comment.data" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorRow from '@/components/row/ErrorRow.vue';
import Inspection from '@/domain/model/inspection/Inspection.js';
import CommentLayout from './CommentLayout.vue';

export default {
  name: 'portals-shared-views-comments-layout',
  components: {
    ErrorRow,
    CommentLayout,
  },
  data() {
    return {
      error: null,
    }
  },
  props: {
    inspectionData: {},
  },
  computed: {
    ...mapGetters([
                   'domain',
                   ]),
    comments: function() {
      var values = [];
      var inspection = new Inspection(this.domain, this.inspectionData);
      var comments = inspection.comments().find();
      values = comments.sorted();
      return values
    },
  },
  mounted: function () {
  },
  watch: {
  },
  methods: {
  },
}
</script>
