<template>
  <div class="row">
    <div class="rc-box-col-15">
      <div class="container">
        
        <div class="row">
          <div class="rc-box-col-14">
          </div>
          <div class="rc-box-col-1">
            <Button label="Close" v-on:click="close" />
          </div>
        </div>
        
        <div class="row rc-background" v-if="!showInspectionRepairs">
          <div class="rc-box-col-15">
            <InspectionDetails 
              :inspectionId="inspectionId"
              :emitActions="false"
              v-on:repair="handleRepairAction"
              />
          </div>
        </div>
        
        <div class="row rc-background" v-if="showInspectionRepairs">
          <div class="rc-box-col-15">
            <InspectionRepairs
              :inspectionId="inspectionId"
              :emitActions="false"
              v-on:repair="handleRepairSaveAction"
              />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

//import GreenButton from '@/components/button/GreenButton.vue';
import Button from '@/components/button/Button.vue';
//import ErrorRow from '@/components/row/ErrorRow.vue';
//import SpacerRow from '@/components/row/SpacerRow.vue';

//import ProgressRow from '@/components/row/ProgressRow.vue';
//import StringUtils from '@/utils/StringUtils.js';

//import TimesheetMap from "@/domain/model/timesheet/TimesheetMap.js";
//import Employee  from "@/domain/model/employee/Employee.js";

import Catelog from '@/domain/session/CanadianEnglish.js';
import InspectionDetails from '@/views/portal/inspections/InspectionDetails.vue'
import InspectionRepairs from '@/views/portal/inspections/InspectionRepairs.vue'

export default {
  name: "inspection-detail-dialog",
  components: {
    InspectionDetails,
    InspectionRepairs,
    Button,
  },
  props: {
    inspectionId: { type: String, default: null },
  },
  data() {
    return {
      error: null,
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
      showInspectionRepairs: false,
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      ]),
  },
  watch: {
  },
  methods: {
    close: function() {
      this.$emit("close");
    },
    handleRepairAction: function() {
      this.showInspectionRepairs = true;
    },
    handleRepairSaveAction: function() {
      this.showInspectionRepairs = false;
    },
  },
};
</script>