<template>
<div class="fullscreen">
    
    
    <Box>
      <ErrorRow :error="error" />
    
      <TitleRow :left="4" :right="4"> {{ MC.Compliance.Reminder.Title.value() }} </TitleRow>
      
      <Row>
        <Column :width="4" />
        <Column :width="7" :showBorder="true">
            <Label align="centre" fontSize="xxxlarge">{{ MC.Compliance.Confirm.value() }}</Label>
            <Value align="centre" fontSize="large">
             {{ MC.Compliance.Reminder.Message.value([employee.user().find().fullName()]) }}
            </Value>
        </Column>
        <Column :width="4" />
      </Row>
      
      <Row>
        <div class="rc-box-col-4" />
        <div class="rc-box-col-7">
          <GreenButton v-on:click="confirm" 
            :disabled="isReminding" 
            :label="MC.Compliance.Reminder.Send.value()" />
        </div>
        <div class="rc-box-col-4" />
      </Row>
      
      <Row>
        <div class="rc-box-col-4" />
        <div class="rc-box-col-7">
          <Button v-on:click="reject" 
            :disabled="isReminding" 
            :label="MC.Compliance.Reminder.Back.value()" />
        </div>  
        <div class="rc-box-col-4" />
      </Row>
    
      <SpacerRow />
      
      <Row>
        <Column>
        </Column>
      </Row>
      
    </Box>
  
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import GreenButton from '@/components/button/GreenButton.vue';
import Button from '@/components/button/BackButton.vue';
import ErrorRow from '@/components/row/ErrorRow.vue';

//import ProgressRow from '@/components/row/ProgressRow.vue';
//import StringUtils from '@/utils/StringUtils.js';

import TimesheetMap from "@/domain/model/timesheet/TimesheetMap.js";
import Employee  from "@/domain/model/employee/Employee.js";
import Label from "@/portals/shared/library/label/Label.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import Catelog from '@/domain/session/CanadianEnglish.js';

import MC      from "@/domain/session/MC.js";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

export default {
  name: "confirm-reminder",
  components: {
    ErrorRow,
    GreenButton,
    Button,
    SpacerRow, TitleRow,
    Box, Row, Column,
    Value, Label,
  },
  props: {
    timesheetData: { type: Object, default: null },
    employeeData:  { type: Object, default: null },
  },
  data() {
    return {
      isReminding: false,
      error: null,
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
      'domain',
      ]),
    timesheets: function() {
      return new TimesheetMap(this.domain, this.timesheetData);
    },
    employee: function() {
      return new Employee(this.domain, this.employeeData);
    },
    message: function() {
      return "Would you like to send a reminder email to complete the Hours of Service?";
    }
  },
  watch: {
  },
  methods: {
    confirm: function () {
      this.isReminding = true;
      this.$emit("closeConfirmed", {
        Employee: this.employee.data, 
        TimesheetMap: this.timesheets.data });
    },
    reject: function () {
      this.$emit("closeRejected");
    },
  },
};
</script>