<template>
  <Box>
    <Row>
      <Column :width="15">
        <Compliance v-if="!isLoading" />
      </Column>
    </Row>
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <SpacerRow v-if="isLoading" />
  </Box>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import MC from "@/domain/session/MC.js";
import ConnectionUtils from '@/utils/ConnectionUtils.js';

import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';

import Compliance from "@/portals/customer/operator/views/compliance/Compliance.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";  

export default {
  name: "admin-company-stats-row",
  components: {
    Compliance, LoadingRow, SpacerRow,
    Box, Row, Column,
  },
  props: {
  },
  data() {
    return {
      isLoading: true,
      loadingMessage: "",
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                 ]),
    companyId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.message = null;
      this.loadingMessage = "";
      ConnectionUtils.loadCompany(this);
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
  }
}
</script>