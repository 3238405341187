
<template>
  <div>
    <ErrorRow :error="error" />

    <LabelValueRow>
      <template #label>Date</template>
      <template #value>{{comment.createdDateDisplay()}}</template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>User</template>
      <template #value>{{fullName()}}</template>
    </LabelValueRow>

    <LabelValueRow>
      <template #label>Remark</template>
      <template #value>{{comment.text()}}</template>
    </LabelValueRow>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorRow from '@/components/row/ErrorRow.vue';
import Comment from "@/domain/model/comment/Comment.js";
import LabelValueRow from '@/portals/shared/library/labelvalue/LabelValueRow.vue';

export default {
  name: 'portals-shared-views-comment-layout',
  components: {
    ErrorRow,
    LabelValueRow,
  },
  data() {
    return {
      error: null,
    }
  },
  props: {
    commentData: {},
  },
  computed: {
    ...mapGetters([
                   'domain',
                   ]),
    comment: function() {
      var value = new Comment(this.domain, this.commentData);
      return value;
    },
  },
  mounted: function () {
  },
  watch: {
  },
  methods: {
    fullName: function() {
      var employee = this.comment.employee().find();
      var user = employee.user().find();
      var name = user.fullName();
      return name;
    }
  },
}
</script>