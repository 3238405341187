<template>
  <div>

      <Spacer />
      
      <Box>
        <ErrorRow :error="error" />
      </Box>

      <!-- Menu -->
      <Box v-if="id && !(isLoadingData || isLoading)">
        <MenuRow 
          :showBorder="true"
          v-on:refresh="refresh()"
          :inspectionId="id" 
          :isAdmin="isAdmin" 
          selected="details"/>
      </Box>
      
      <Loading 
        :showBorder="true" 
        v-if="isLoading || isLoadingData">{{ loadingMessage }}
      </Loading>
            
      <Box v-else :showBorder=true>
        <!-- Company Details-->
        <TitleRow align="left">Company</TitleRow>
        
        <!-- Company -->
        <LabelValueRow>
          <template #label>Company</template>
          <template #value>{{ Contents.decode(inspection.company().find().name()) }}</template>
        </LabelValueRow>

        <!-- Address -->
        <LabelValueRow>
          <template #label>Address</template>
          <template #value>{{ Contents.decode(inspection.company().find().address().find().location()) }}</template>
        </LabelValueRow>

        <!-- Inspection Details -->
        <TitleRow align="left">
          Inspection Details
        </TitleRow>
 
        <LabelValueRow>
          <template #label>Date</template>
          <template #value>{{inspection.createdDateDisplay()}}</template>
        </LabelValueRow>

        <LabelValueRow>
          <template #label>Address</template>
          <template #value>{{ Contents.decode(inspection.location()) }}</template>
        </LabelValueRow>
        
        <LabelValueRow>
          <template #label>Trip Type</template>
          <template #value>{{ inspection.tripDisplay() }}</template>
        </LabelValueRow>
      
        
        <LabelValueRow>
          <template #label>Status</template>
          <template #value>
            <Box>
              <Row>
                <div class="rc-box-col-3 rc-input-good rc-text-centre" v-if="inspection.isDefectTypeGood()">
                  {{ inspection.defectTypeDisplay() }}
                </div>
                <div class="rc-box-col-3 rc-input-minor rc-text-centre" v-else-if="inspection.isDefectTypeMinor()">
                  {{ inspection.defectTypeDisplay() }}
                </div>
                <div class="rc-box-col-3 rc-input-major rc-text-centre" v-else-if="inspection.isDefectTypeMajor()">
                  {{ inspection.defectTypeDisplay() }}
                </div>
                <div class="rc-box-col-6" />
              </Row>
            </Box>
          </template>
        </LabelValueRow>
        
        <!-- Vehicle -->
        <TitleRow align="left"> Vehicle </TitleRow>
        
        <Row>
          <Column :width="15">
            <Result v-if="vehicleResults"
              :initialResult="vehicleResults.initial().data" 
              :enrouteResult="vehicleResults.enroute().data"
              :inspectionData="inspection.data" />
          </Column>
        </Row>
        
        <!-- Trailer inspection -->
        <TitleRow align="left"> Trailers </TitleRow>
        <Row v-if="!inspection.hasTrailers()">
          <Column :width="15" v-for="trailer in inspection.trailers().list()" :key="trailer.data['@rid']">
            <Result v-if="trailerResults"
              :forVehicle="false" 
              :initialResult="trailerResults.forVehicle(trailer).initial().data" 
              :enrouteResult="trailerResults.forVehicle(trailer).enroute().data" 
              :inspectionData="inspection.data" />
          </Column>
        </Row>
        <LabelValueRow v-else>
          <template #label></template>
          <template #value>No Trailers</template>
        </LabelValueRow>
        
        
        <!-- comments -->
        <TitleRow align="left">Remarks</TitleRow>
        <CommentsLayout v-if="inspection.hasComments()" :inspectionData="inspection.data"/>
        <LabelValueRow  v-else>
          <template #label></template>
          <template #value>No Remarks</template>
        </LabelValueRow>
        
        <!-- Inspector -->
        <TitleRow align="left">Initial Inspection</TitleRow>
        <LabelValueRow>
          <template #label>Inspected By</template>
          <template #value>{{inspection.inspector().find().user().find().fullName()}}</template>
        </LabelValueRow>
        
        <LabelValueRow>
          <template #label>Signature</template>
          <template #value>
            <img width="100px" :src="inspection.inspector().find().user().find().signature().find().image()" />
          </template>
        </LabelValueRow>
      
        <LabelValueRow>
          <template #label></template>
          <template #value>{{ inspection.schedule().find().nameDisplay() }}</template>
        </LabelValueRow>
        
        <!-- Driver -->
        <TitleRow align="left" > Driver </TitleRow>
        
        <LabelValueRow>
          <template #label>Driven By</template>
          <template #value v-if="inspection.driver().isNew()">No driver set</template>
          <template #value v-else>{{ inspection.driver().find().user().find().fullName() }}</template>
        </LabelValueRow>


        <LabelValueRow>
          <template #label>Signature</template>
          <template #value>
            <img width="100px" :src="inspection.driver().find().user().find().signature().find().image()" />
          </template>
        </LabelValueRow>
        
        <!-- Enroute reporter -->
        <Row v-if="inspection.hasEnrouteInspector()">
          <Column>
            <Box>
              <TitleRow align="left"> Enroute/In Trip Inspection </TitleRow>
              
              <LabelValueRow>
                <template #label>Date</template>
                <template #value>{{ inspection.enrouteInspectionDateDisplay() }}</template>
              </LabelValueRow>
              
              <LabelValueRow>
                <template #label>Inspected By</template>
                <template #value>{{ inspection.enrouteInspector().find().user().find().fullName() }}</template>
              </LabelValueRow>
               
              <LabelValueRow>
                <template #label>Signature</template>
                <template #value>
                  <img width="100px" :src="inspection.enrouteInspector().find().user().find().signature().find().image()" />
                </template>
              </LabelValueRow>
              
              <LabelValueRow>
                <template #label></template>
                <template #value></template>
              </LabelValueRow>
            </Box>
          </Column>
        </Row>

        <!-- Repaired By -->
        <div v-if="inspection.hasRepairs()">
          <RepairResult :inspectionData="inspection.data" />
        </div>
        <!-- End Repaired By -->
      </Box>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ContentUtils from '@/utils/ContentUtils.js'
import StringUtils from '@/utils/StringUtils.js'
import ConstUtils from '@/utils/ConstUtils.js'
import DateUtils from '@/utils/DateUtils.js'
import ConnectionUtils from '@/utils/ConnectionUtils.js';

import ErrorRow from '@/components/row/ErrorRow.vue'

import CommentsLayout from '@/portals/shared/views/comments/CommentsLayout.vue'

import MC from "@/domain/session/MC.js";

import Result from '@/views/portal/results/ResultDetails.vue'
import RepairResult from '@/views/portal/repairs/result/RepairResultDetail.vue'
import Inspection from '@/domain/model/inspection/Inspection.js';
import ResultMap from '@/domain/model/inspection/result/ResultMap.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";

import Spacer from "@/portals/shared/library/spacer/Spacer.vue";
import MenuRow from '@/portals/customer/operator/views/inspection/MenuRow.vue';

import LabelValueRow from "@/portals/shared/library/labelvalue/LabelValueRow.vue";
import Loading from "@/portals/shared/library/loading/Loading.vue";

export default {
  name: 'portal-inspection-details',
  components: {
    ErrorRow,
    /*Title,*/ TitleRow, MenuRow,
    Box,Row, Column,
    /*LabelValue,*/ LabelValueRow, 
    Spacer, Loading,
    Result,
    RepairResult,
    CommentsLayout,
  },
  props: {
    inspectionId: { type: String, default: null },
    emitActions:  { type: Boolean, default: false },
    isAdmin:  { type: Boolean, default: false },
  },
  data() {
    return {
      id: this.$route.params['id'] ? this.$route.params['id'] : this.inspectionId,
      questions: [],
      vehicleResults: null,
      trailerResults: null,
      error: null,
      isLoadingData: true,
      Strings: StringUtils,
      Contents: ContentUtils,
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Conts: ConstUtils,

    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
    ]),
    
    inspection: function() {
      if (this.id && this.domain) {
        return this.domain.inspections().findById(this.id);
      }
      return new Inspection(this.domain, {});
    }
  },
  watch: {
    inspectionId() {
      this.id = this.inspectionId;
      this.queryInspectionData();
    },
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Loading.value();
      this.queryInspectionData();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    hasUnfixedDefects: function() {
      var inspectionObj = this.inspection;
      return inspectionObj.hasUnfixedDefect();
    },
    repairs: function() {
      if (this.emitActions) {
        this.$emit('repair', this.inspection.id());
      } else {
        var params = {
          id: this.inspection.id(),
        }
        this.$router.push({
          name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
          params: params,
        });
      }
    },
    
    refresh: function () {
      this.isLoadingData = true;
      this.queryInspectionData();
    },
    
    queryInspectionData: function () {
      this.isLoadingData = true;
      var event = this.domain.events().inspectionDetails(this.id);
      event.send(this.listener);
    },
    listener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var insp = this.inspection;
        var payload = event.payload();
        var map = payload.valuesFor(ResultMap.MODEL_NAME);
        var allResults = new ResultMap(this.domain, map);
        this.vehicleResults = allResults.vehicle();
        this.trailerResults = allResults.trailers();
        this.vehicle = insp.vehicle().find();        
        this.id = this.inspection.id();
      }
      this.isLoadingData = false;
    },
    toDateTime: function (time) {
      return DateUtils.toDateTime(time)
    },
    isEven: function (position) {
      return StringUtils.isEven(position)
    },
  },
}
</script>