<template>
  <div >
    <InspectionComponent v-for="(inspection) in inspectionList" 
      :key="inspection.id()" 
      :inspectionData="inspection.data" 
      :showLabels="showLabels"
      v-on:showInspection="showInspection"
      :rowIndex="rowIndex" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog         from "@/domain/session/Catalog.js";

//import ConstUtils      from '@/utils/ConstUtils.js';

//import Inspection     from "@/domain/model/inspection/Inspection.js";
import InspectionMap  from "@/domain/model/inspection/InspectionMap.js";
import InspectionComponent from "./ComplianceTableCellInspection.vue";

export default {
  name: 'portal-compliance-table-cell-inspections',
  components: {
    InspectionComponent,
  },
  props: {
    inspectionsData: {type: Object, default: null},
    rowIndex: {type: Number, default: 0},
    showLabels: { type: Boolean, default: false },
  },
  data() {
    return {
      C: new Catelog(),
      inspections: new InspectionMap(this.domain),
      inspectionList: [],
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
  },
  watch: {
    inspectionsData() {
      this.inspections = new InspectionMap(this.domain, this.inspectionsData);
      this.inspectionList = this.inspections.list();
    }
  },
  mounted: function () {
    this.inspections = new InspectionMap(this.domain, this.inspectionData);
    this.inspectionList = this.inspections.list();
  },
  methods: {
    showInspection: function (inspectionId) {
      this.$emit('showInspection', inspectionId);
    },
  },
}
</script>