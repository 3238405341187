<template>
  <div>

    <div :class="rowClass">
      <div class="row" >
        
        <div v-if="checkIn.isNew()" class="rc-box-col-15">
          <img src="@/assets/2022/800x800transparent.png" width="24px" />
        </div>
        
        <div v-else-if="checkIn.isResultGood()" class="rc-box-col-15">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.CheckIn.Status.Good.value() }}</div>
          <img src="@/assets/2022/Portal-CheckInicons-healthy.png" width="24px" />
        </div>

        <div v-else class="rc-box-col-15">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.CheckIn.Status.Sick.value() }}</div>
          <img src="@/assets/2022/Portal-CheckInicons-sick.png" width="24px" />
        </div>
        
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog         from "@/domain/session/Catalog.js";
import MC from "@/domain/session/MC.js";

//import ConstUtils      from '@/utils/ConstUtils.js';
import StringUtils      from '@/utils/StringUtils.js';

import CheckIn     from "@/domain/model/checkin/CheckIn.js";

export default {
  name: 'portal-compliance-table-cell-check-in',
  components: {
  },
  props: {
    checkInData: {type: Object, default: null},
    rowIndex: {type: Number, default: 0},
    showLabels: { type: Boolean, default: false},
  },
  data() {
    return {
      C: new Catelog(),
      checkIn: new CheckIn(this.domain),
      MC: new MC(),
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    rowClass: function() {
      if (StringUtils.isEven(this.rowIndex)) {
        return "rc-background-light-thin container";
      }
      return "rc-background-dark-thin container";
    },
  },
  watch: {
    checkInData() {
      this.checkIn = new CheckIn(this.domain, this.checkInData);
    }
  },
  mounted: function () {
    this.checkIn = new CheckIn(this.domain, this.checkInData);
  },
  methods: {
  },
}
</script>