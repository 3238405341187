<template> 
  <input 
    v-on:click="pressed"
    class="rc-input-button-normal rc-input-width rc-font-small"
    type=button
    :disabled="disabled" 
    :value="label" /> 
</template>

<script>
export default {
  name: 'back-button',
  components: {
  },
  props: {
    disabled: { type: Boolean, default: false },
    label: { type: String, default: 'Back' },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    pressed: function() {
      this.$emit('click');
    },
  },
}
</script>
