
<template>
<Box>
  <Row cmpt="ResultDetailOptionRows" 
      v-for="(option, optionIndex) in optionsSorted" 
      :key="option.data['@rid']" :class="isEven(questionIndex) ? 'rowEven' : 'rowOdd'">
    <Column :width="15">
      
      <Box v-if="optionIndex == 0">
        <Row>
          <Column :width="1"></Column>
          <Column :width="1"></Column>
          <Column :width="1"></Column>
          <Column :width="1"></Column>
          <Column :width="11" class="rc-text-small rc-text-value">{{ type }}</Column>
        </Row>
      </Box>
          
      <Box>
        <Row>
          <Column v-if="initial" cmpt="In" :width="1">
            <img v-if="majors" src="@/assets/2019/major_chek.png" width="18px" />
            <img v-else src="@/assets/2019/minor_chek.png" width="18px" />
          </Column>
          <Column v-else cmpt="In" :width="1">
          </Column>
          
          <Column v-if="!initial" cmpt="En" :width="1">
            <img v-if="majors" src="@/assets/2019/major_chek.png" width="18px" />
            <img v-else src="@/assets/2019/minor_chek.png" width="18px" />
          </Column>
          <Column v-else cmpt="En" :width="1">
          </Column>
          
          <Column cmpt="Re" :width="1">
            <div v-if="isRepaired(question, option)">
              <img src="@/assets/2019/none_chek.png" width="18px" />
            </div>
          </Column>
          
          <Column cmpt="No" :width="1" />
          
          <Column :width="10">
            <div v-if="!option.isRemark()" class="rc-text-value rc-text-small">
              {{ option.position() }})
              {{ option.value() }}
            </div>
          </Column>
          
          <Column v-if="showRepair(question, option)" :width="1">
            <Button v-on:click="repairedAction(option)" :disabled="false">
            Repair
            </Button>
          </Column>
        </Row>
      </Box>
          
    </Column>
  </Row>
  <Row v-if="hasRemarks">

    <Column v-if="initial" cmpt="In" :width="1">
      <img v-if="majors" src="@/assets/2019/major_chek.png" width="18px" />
      <img v-else src="@/assets/2019/minor_chek.png" width="18px" />
    </Column>
    <Column v-else cmpt="In" :width="1">
    </Column>
    
    <Column v-if="!initial" cmpt="En" :width="1">
      <img v-if="majors" src="@/assets/2019/major_chek.png" width="18px" />
      <img v-else src="@/assets/2019/minor_chek.png" width="18px" />
    </Column>
    <Column v-else cmpt="En" :width="1">
    </Column>
    
    <Column cmpt="Re" :width="1">
      <div v-if="isRepairedForNotes(question, remarksOption)">
        <img src="@/assets/2019/none_chek.png" width="18px" />
      </div>
    </Column>
    
    <Column cmpt="No" :width="1" />
    
    <Column :width="10" class="rc-text-small rc-text-value">
      <div class="rc-text-value rc-text-small">
      Remarks: {{ remarks }}
      </div>
    </Column>
    <Column v-if="showRepair(question, remarksOption)" :width="1">
      <Button v-on:click="repairedAction(remarksOption)" :disabled="false">
      Repair
      </Button>
    </Column>
  </Row>
          
</Box>
    
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils from '@/utils/StringUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import Box    from '@/portals/shared/library/box/Box.vue';
import Row    from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import Button from '@/portals/shared/library/button/Button.vue';

export default {
  name: 'portal-result-details-option-rows',
  components: {
    Box, Row, Column,
    Button,
  },
  data() {
    return {
      localRepairs: [],
      question: null,
      options: null,
      optionsSorted: [],
      result: null,
      answers: null,
      remarksOption: null,
      Strings: StringUtils,
      Contents: ContentUtils,
    };
  },
  props: {
    resultId: { type: String, default: "" },
    questionId: { type: String, default: "" },
    majors: { type: Boolean, default: true },
    initial: { type: Boolean, default: true },
    questionIndex: { type: Number, default: 0 },
    forRepair: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters([
      'auth_connected',
      'domain',
    ]),
    remarks: function() {
      if (!this.answers) {
        return "";
      }
      if (this.majors) {
        return this.answers.answerFor(this.question).remarksMajor();
      }
      return this.answers.answerFor(this.question).remarksMinor();
    },
    hasRemarks: function() {
      return !this.Strings.isEmpty(this.remarks);
    },
    type: function() {
      if (this.majors) {
        return "Majors";
      }
      return "Minors";
    },
  },
  mounted: function() {
    this.question = this.domain.questions().findById(this.questionId);
    this.result = this.domain.results().findById(this.resultId);
    this.answers = this.result.answers().find();
    this.options = this.majors ? this.answers.majors(this.question) : this.answers.minors(this.question);
    this.remarksOption = this.majors ? 
        this.domain.options().findNotesMajor() :
        this.domain.options().findNotesMinor();
    this.options.remove(this.remarksOption);
    this.optionsSorted = this.options.sort();
  },
  methods: {
    showRepair: function(question, option) {
      return this.forRepair && !this.isRepaired(question, option);
    },
    showRepairForRemarks: function() {
      return this.forRepair && !this.isRepaired(this.question, this.remarksOption);
    },
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    isRepairedForNotes: function(question, option) {
      return this.isRepaired(question, option);
    },
    isRepaired: function(question, option) {
      if (this.localRepairs.includes(option.id())) {
        return true;
      }
      return this.answers.isRepaired(question, option);
    },
    repairedActionForNotes: function() {
      this.repairedAction(this.remarksOption);
    },
    repairedAction: function (option) {
      var answer = this.answers.answerFor(this.question);
      if (option) {
        let details = { 
            'optionId': option.id(), 
            'questionId': this.questionId,
            'answerId': answer.id(),
            'resultId': this.resultId,
            };
        this.localRepairs.push(option.id());
        this.$emit('repairAction', details);
      }
      this.optionsSorted = this.options.sort();
    },
  },
}
</script>