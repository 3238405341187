<template>
<Box>
  
  <ErrorRow :error="error" />
  
  <Row><!-- row 1 -->
    <Column :showBorder="true" :width="10">
      <Box>
      
        <Row>
          <Column :width="2" class="rc-text-label">
            Filter
          </Column>
          <Column :width="2">
            <Button
                v-on:click="setFilterType('all')" 
                :disabled="isFilterType('all')" 
                >All</Button>
          </Column>
          
          <Column :width="2">
            <Button v-on:click="setFilterType('included')" 
                :disabled="isFilterType('included')">Include</Button>
          </Column>

          <Column :width="2">
            <Button v-on:click="setFilterType('excluded')" 
                :disabled="isFilterType('excluded')">Hidden</Button>
          </Column>

          <Column :width="7" />
        </Row>
        
        <Row>
          <div class="rc-box-col-2 rc-font-medium rc-text-label">
            Search
          </div>
          
          <div class="rc-box-col-5">
            <div class="container">
              <KeyValue 
                title="search"
                :clearValue="clear"
                v-on:enter="startSearch"
                v-on:value="searchValue"
              />
            </div>
          </div>
          
          <div class="rc-box-col-1">
            <Button v-on:click="startSearch" 
              :disabled="isLoadingEmployees" 
              >Go</Button>
          </div>
          
          <div class="rc-box-col-1">
            <Button v-on:click="clearSearch" 
              :disabled="isLoadingEmployees">
                Clear
              </Button>
          </div>
      
          <div class="rc-box-col-3 rc-font-small rc-text-value" >
          </div>
          
          <div class="rc-box-col-4">
          </div> <!-- col-4 -->
          
        </Row>
      </Box>
    </Column> 
    <Column :width="5" :showBorder="true">
        <Box>
          <Row>
            <div class="rc-box-col-1 rc-text-label">Actions</div>
            <div class="rc-box-col-2" />
            <div class="rc-box-col-6">
              <Button v-on:click="toggleLabels()">
                {{ showLabelsLabel }}
              </Button>
            </div>
            <div class="rc-box-col-6">
              <Button v-on:click="refresh()" :disabled="isLoadingEmployees">
                Refresh
              </Button>
            </div>
          </Row>

          <Row>
           <Column :width="3" class="rc-text-label">Range</Column>
           <Column :width="1">
            <Button v-if="canNextPageDate()" :disabled="isLoadingInspections || isLoadingTimesheets || isLoadingEmployees" v-on:click="nextPageDate()">&lt;</Button>
           </Column>
           <Column :width="10"><div class="rc-text-centre rc-text-value">
            {{ startDate() }} to
            {{ endDate() }}
           </div>
           </Column>
           <Column :width="1">
            <Button v-if="canPreviousPageDate()" :disabled="isLoadingInspections || isLoadingTimesheets || isLoadingEmployees" v-on:click="previousPageDate()">&gt;</Button>
           </Column>
          </Row>

        </Box>
    </Column>
          
  </Row> <!-- row 1 -->

  <TitleRow> 
    <span> Users {{ isLoadingEmployees ? 'Loading ...' : this.employeeMap.size() }} </span>
    <span v-if="hasTimesheets()"> Hours {{ isLoadingTimesheets? 'Loading ...' : this.timesheetMap.size() }} </span>
    <span v-if="hasInspections()"> Inspections {{ isLoadingInspections ? 'Loading ...' : this.inspectionMap.size() }} </span>
    <span v-if="hasCheckIns()"> Check-Ins {{ isLoadingCheckIns ? 'Loading ...' : this.checkInMap.size() }} </span>
  </TitleRow>

  <Row :showBorder="true">
    <Column>
      <Box>
        <div v-for="(employee, eIndex) in employeeList" :key="employee.id()" :class="isEven(eIndex) ? 'rowEven row' : 'rowOdd row'" style="padding: 8px">
          <ComplianceTableCell 
            :employeeData="employee.data"
            :timesheetMapData="timesheetDataForEmployee(employee)"
            :inspectionMapData="inspectionMap.findForEmployee(employee).data"
            :checkInMapData="checkInMap.findForEmployee(employee).data"
            :rowIndex="eIndex"
            :showLabels="showLabels"
            :stillLoadingTimesheets="isLoadingTimesheets"
            :startFilterDate="startFilterDate.time()"
            />
          <div class="rc-box-col-1">
            <div v-if="employee.hasBeenExcluded()">
              <Button 
                v-on:click="include(employee.data)" 
                :disabled="isSavingEmployee">Include</Button>
            </div>
            <div v-else>
              <Button 
                v-on:click="exclude(employee.data)" 
                :disabled="isSavingEmployee"> Hide </Button>
            </div>
            <div v-if="hasTimesheets() && timesheetMap.hasIncomplete() && !isLoadingTimesheets">
              <br>
              <Button 
                v-on:click="remind(employee, timesheetMap)" 
                :disabled="isSendingReminder">Remind</Button>
            </div>
          </div>
        </div>
      </Box>
    </Column>
  </Row>
  
  <LoadingRow :showBorder="true" v-if="isLoadingEmployees || isLoadingTimesheets || isLoadingCheckIns || isLoadingInspections"> {{ loadingMessage }} </LoadingRow>
    
  <Row :showBorder="true" v-if="!isLoadingEmployees">
    <div class="rc-box-col-4" />
    <div class="rc-box-col-1">
      <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
    </div>
    
    <div class="rc-box-col-5 rc-text-centre rc-text-label">
      Showing {{ min }} to {{ max }}  of {{ total }}
    </div>

    <div class="rc-box-col-1">
      <Button v-if="canNextPage()"  v-on:click="nextPage"> &gt; </Button>
    </div>
    <div class="rc-box-col-4" />
  </Row>
        
  <ConfirmReminder 
    v-if="reminding" 
    v-on:closeRejected="closeRejected" 
    v-on:closeConfirmed="closeConfirmed" 
    :employeeData="selectedEmployee.data"
  />
  
</Box> <!-- container -->
</template>

<script>
import { mapGetters } from 'vuex'

import StringUtils from '@/utils/StringUtils.js'
//import ConstUtils  from '@/utils/ConstUtils.js'
import MC from "@/domain/session/MC.js";

import TimesheetMap        from '@/domain/model/timesheet/TimesheetMap.js';
import TimesheetListFilter from "@/domain/model/timesheet/TimesheetListFilter.js";
    
import Employee           from "@/domain/model/employee/Employee.js";
import EmployeeMap        from "@/domain/model/employee/EmployeeMap.js";
import EmployeeListFilter from "@/domain/model/employee/EmployeeListFilter.js";
import InspectionMap        from "@/domain/model/inspection/InspectionMap.js";
import InspectionListFilter from "@/domain/model/inspection/InspectionListFilter.js";

import CheckInMap        from "@/domain/model/checkin/CheckInMap.js";
import CheckInListFilter from "@/domain/model/checkin/CheckInListFilter.js";

import Catelog        from "@/domain/session/Catalog.js";

import KeyValue       from '@/components/input/KeyValue.vue';
import ErrorRow       from '@/components/row/ErrorRow.vue'
import ConfirmReminder from './ConfirmReminder.vue';
import ComplianceTableCell from './ComplianceTableCell.vue';
import RcTime from "@/domain/session/time/RcTime.js";


import Box from '@/portals/shared/library/box/Box.vue';
import Row from '@/portals/shared/library/box/Row.vue';
import Column from '@/portals/shared/library/box/Column.vue';
import Button from '@/portals/shared/library/button/Button.vue';
import TitleRow from '@/portals/shared/library/title/TitleRow.vue';
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

export default {
  name: 'portal-compliance-table',
  components: {
    ErrorRow,
    TitleRow, LoadingRow,
    ComplianceTableCell,
    KeyValue,
    ConfirmReminder,
    Button,
    Box, Row, Column
  },
  props: {
    start: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      C: new Catelog(),
      employeeMap: new EmployeeMap(this.domain),
      timesheetMap: new TimesheetMap(this.domain),
      inspectionMap: new InspectionMap(this.domain),
      checkInMap: new CheckInMap(this.domain),
      isLoadingTimesheets: false,
      isLoadingCheckIns: false,
      isLoadingInspections: false,
      isLoadingEmployees: false,
      MC: new MC(),
      showMissing: false,
      employeeState: 'active',
      currentDateFilter: 'last2Weeks',
      total: 0,
      pageSize: 20,
      pageNumber: 0,
      complianceType: 'included',
      employeeList: [],
      clear: false,
      Strings: StringUtils,
      isSavingEmployee: false,
      showLabelsLabel: 'Show Labels',
      showLabels: false,
      isSendingReminder: false,
      reminding: false,
      selectedEmployee: null,
      loadingMessage: null,
      isLoadingDate: false,
      startFilterDate: RcTime.time().now().day(),
      RcTime: RcTime,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    max: function() {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function() {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
  },
  watch: {
    start() {
      if (this.start) {
        this.sendInitialRequest();
      }
    }
  },
  mounted: function () {
    if (this.start) {
      this.sendInitialRequest();
    }
  },
  
  methods: {
    timesheetDataForEmployee: function(employee) {
      var timesheets = this.timesheetMap.findFor(employee);
      var sorted = timesheets.sort();
      var user = employee.user().find();
      if (sorted && user && timesheets.size() > 0) {
        this.domain.logger().info("data");
      }
      return timesheets.data
    },
    sendInitialRequest: function() {
      this.loadByFilter();
    },
    
    loadByFilter: function () {
      this.loadEmployees();
    },
    
    loadEmployees: function() {
      this.employeeList = [];
      this.employeeMap = new EmployeeMap(this.domain);

      this.timesheetMap = new TimesheetMap(this.domain);
      this.inspectionMap = new InspectionMap(this.domain);
      this.employeeToTimesheets = {};
      
      if (!this.domain) {
        return;
      }
      this.isLoadingEmployees = true;
      this.loadingMessage = this.MC.Compliance.Status.LoadingEmployees.value();
      var filter = new EmployeeListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withState(this.employeeState)
        .withComplianceType(this.complianceType)
        .withCompany(this.domain.session().company().id())
        .done();
      
      if (!StringUtils.isEmpty(this.searchFor)) {
        filter
          .withSearchText(this.searchFor)
          .done()
      }
      
      var event = this.domain.events().employees().list(filter);
      event.with("signatures", false);
      
      event.send(this.employeesLoadedListener);
    },
    
    employeesLoadedListener: function(event) {
      this.isLoadingEmployees = false;
      this.loadingMessage = null;
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        this.total = payload.count();
        this.employeeMap = new EmployeeMap(this.domain, payload.valuesFor(EmployeeMap.MODEL_NAME));
        this.employeeList = this.employeeMap.sortByUserFirst();
        this.error = null;
        this.loadingMessage = this.MC.Compliance.Status.LoadingRest.value();
        this.loadCheckInsByFilter();
        this.loadTimesheetsByFilter();
        this.loadInspectionsByFilter();
      }
    },
    
    loadTimesheetsByFilter: function() {
      this.timesheetMap = new TimesheetMap(this.domain);
      if (!this.hasTimesheets()) {
        return;
      }
      this.isLoadingTimesheets = true;
      
      var filter = new TimesheetListFilter();
      filter
        .withDateRange("last2Weeks(" + this.startFilterDate.time() + ")")
        .withPagination(-1, -1)
        .withEmployees(this.employeeMap.pointers())
        .withCompany(this.domain.session().company().id())
        .done();
      
      var event = this.domain
          .events()
          .timesheets().list(filter);
      event.with("users", false);
      event.send(this.timesheetListListener);
    },
    
    timesheetListListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(TimesheetMap.MODEL_NAME);
        if (map) {
          this.timesheetMap = new TimesheetMap(this.domain, map.data);
        }
      }
      this.isLoadingTimesheets = false;
    },
    
    loadInspectionsByFilter: function() {
      this.inspectionMap = new InspectionMap(this.domain);
      if (!this.hasInspections()) {
        return;
      }
      this.isLoadingInspections = true;
      var filter = new InspectionListFilter();
      filter
        .withDateRange("last2Weeks(" + this.startFilterDate.time() + ")")
        .withPagination(-1, -1)
        .withEmployees(this.employeeMap.pointers())
        .done();
      
      filter
        .withCompany(this.domain.session().company().id())
        .done();
      
      var event = this.domain
          .events()
          .inspections().list(filter);
      event.with("populate", false);
      event.send(this.inspectionsListListener);

    },
    
    inspectionsListListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(InspectionMap.MODEL_NAME);
        if (map) {
          this.inspectionMap = new InspectionMap(this.domain, map);
        }
      }
      this.isLoadingInspections = false;
    },
    
    loadCheckInsByFilter: function() {
      this.checkInMap = new CheckInMap(this.domain);
      if (!this.hasCheckIns()) {
        return;
      }
      this.isLoadingCheckIns = true;
      var filter = new CheckInListFilter(this.domain);
      filter
        .withDateRange("last2Weeks(" + this.startFilterDate.time() + ")")
        .withPagination(-1, -1)
        .withCompany(this.domain.session().company().id())
        .withEmployees(this.employeeMap.pointers())
        .done();
      var event = this.domain.events().checkins().list(filter);
      event.with("populate", false);
      event.send(this.checkInsListListener);
    },
    
    checkInsListListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(CheckInMap.MODEL_NAME);
        this.checkInMap = new CheckInMap(this.domain, map);
      }  
      this.isLoadingCheckIns = false;
    },
    
    
    hasInspections: function() {
      if (!this.domain) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsInspections();
      return result;
    },
    
    hasCheckIns: function() {
      if (!this.domain) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckIns();
      return result;
    },
    
    hasTimesheets: function() {
      if (!this.domain) {
        return false;
      }
      var result = true;
      result = this.domain.session().company().features().find().allowsTimesheets();
      return result;
    },
    
    isEven: function (position) {
      return StringUtils.isEven(position);
    },
    
    refresh: function () {
      this.loadByFilter();
    },
    
    previousPage: function() {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadByFilter();
      }
    },
    canPreviousPage: function() {
      return this.pageNumber > 0;
    },
    canNextPage: function() {
      return this.total > (this.pageSize * this.pageNumber) + this.pageSize;
    },
    nextPage: function() {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadByFilter();
      }
    },
    
    startSearch: function() {
      this.loadByFilter();
    },
    clearSearch: function() {
      this.searchFor = null;
      this.clear = true;
      this.loadByFilter();
    },
    searchValue: function(value) {
      this.clear = false;
      this.searchFor = value['value'];
    },
    
    showDate: function(index) {
      if (this.domain) {
        return this.domain.session().time().now().minus(index - 1).displayAs_MM_DD();
      }
      return "";
    },
    
    setFilterType: function(type) {
      if (type) {
        this.complianceType = type;
        this.pageNumber = 0;
      }
      this.loadByFilter();
    },
    isFilterType: function(key) {
      return this.complianceType === key;
    },
 
    exclude: function(employeeData) {
      this.domain.logger().info("exclude!");
      this.error = null;
      this.isSavingEmployee = true;
      var employee = new Employee(this.domain, employeeData);
      
      var map = new EmployeeMap(this.domain);
      map.add(employee);
      map.markExcluded();
      
      var event = this.domain.events().employees().save(map);
      event.send(this.employeeSaveListener);
    },
    
    include: function(employeeData) {
      this.isSavingEmployee = true;
      this.domain.logger().info("include!");
      this.error = null;
      var employee = new Employee(this.domain, employeeData);
      var map = new EmployeeMap(this.domain);
      map.add(employee);
      map.markIncluded();
      
      var event = this.domain.events().employees().save(map);
      event.send(this.employeeSaveListener);
    },
    
    employeeSaveListener: function(event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var map = payload.valuesFor(EmployeeMap.MODEL_NAME);
        if (map) {
          this.inspectionMap = new InspectionMap(this.domain, map);
        }
      }
      this.isSavingEmployee = false;
      this.loadByFilter();
    },
    toggleLabels: function() {
      this.showLabels = !this.showLabels;
      if (this.showLabels) {
        this.showLabelsLabel = "Hide Labels";
      } else {
        this.showLabelsLabel = "Show Labels";
      }
    },
    remind: function(employee) {
      this.selectedEmployee = employee;
      this.reminding = true;
    },
    closeRejected: function() {
      this.reminding = false;
    },
    closeConfirmed: function() {
      this.reminding = false;
      this.isSendingReminder = true;
      
      var event = this.domain.events().timesheets().reminder();
      
      event.with("startDay", 0);
      event.with(EmployeeMap.MODEL_NAME, this.selectedEmployee.map().data);
      
      event.send(this.timesheetReminderListener);
    },
    timesheetReminderListener: function(event) {
      this.isSendingReminder = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.error = null;
      }
    },
    
    startDate: function() {
      if (!this.domain) {
        return "";
      }
      return this.startFilterDate.displayAs_MM_DD(true); 
    },
    
    endDate: function() {
      if (!this.domain) {
        return "";
      }
      return this.startFilterDate.minus(13).displayAs_MM_DD(true);
    },
    
    canPreviousPageDate: function() {
      return true;
    },
    
    previousPageDate: function() {
      this.startFilterDate = this.startFilterDate.minus(13);
      this.domain.logger().info("previousPageDate {0}", [this.startFilterDate.displayAs_MM_DD(true)]);
      this.loadByFilter();
    },
    
    canNextPageDate: function() {
      var now = this.domain.session().time().now().day().firstHour();
      var nowFirstHourValue = now.value();
      
      var otherFirstHour = this.startFilterDate.firstHour();
      var otherFirstHourValue = otherFirstHour.value();
      
      var result = nowFirstHourValue != otherFirstHourValue;
      return result;
    },
    
    nextPageDate: function() {
      this.startFilterDate = this.startFilterDate.plus(13);
      this.domain.logger().info("nextPageDate {0}", [this.startFilterDate.displayAs_MM_DD(true)]);
      this.loadByFilter();
    }
  },
}
</script>