<template>
  <div>
  
    <div v-if="inspection.isNew()" :class="rowClass">
      <div class="row" v-if="showLabels">
        <div class="rc-box-col-15 rc-font-small rc-text-value" >
          <br >
        </div>
      </div>

      <div class="row">
        <div class="rc-box-col-15">
          <div> <img src="@/assets/2022/800x800transparent.png" width="24px" /> </div>
        </div>
      </div>
    </div>
    
    <div v-else :class="rowClass">  
      <div class="row" v-if="showLabels">
        <div class="rc-box-col-15 rc-font-small rc-text-value" >
        {{ inspection.vehicle().find().plate() }}
        </div>
      </div>
      <div class="row clickable">
        <div v-if="inspection.isDefectTypeGood()" class="rc-box-col-15" v-on:click="details">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Inspection.Status.Good.value() }}</div>
          <img src="@/assets/2022/InspectionCompleteGood.png" width="24px" />
        </div>
        <div v-else-if="inspection.isDefectTypeMinor()" class="rc-box-col-15" >
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Inspection.Status.Minor.value() }}</div>
          <img src="@/assets/2022/InspectionCompleteMinor.png" width="24px" v-on:click="repair" />
        </div>
        <div v-else-if="inspection.isDefectTypeMajor()" class="rc-box-col-15">
          <div v-if="showLabels" class="rc-font-small rc-text-value rc-text-centre">{{ MC.Inspection.Status.Major.value() }}</div>
          <img src="@/assets/2022/InspectionCompleteMajor2.png" width="24px" v-on:click="repair" />
        </div>
      </div>
      
      <div class="row" v-if="showLabels">
        <div class="rc-box-col-15 rc-font-small rc-text-value" >
        {{ inspection.tripDisplay() }}
        </div>
      </div>
      
      <div class="row" v-if="showLabels">
        <div class="rc-box-col-15 rc-font-small rc-text-value" >
        {{ inspection.createdDate().displayAs_HH_MM_A() }}
        </div>
      </div>
      
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog         from "@/domain/session/Catalog.js";
import MC from "@/domain/session/MC.js";

import ConstUtils      from '@/utils/ConstUtils.js';
import StringUtils      from '@/utils/StringUtils.js';

import Inspection     from "@/domain/model/inspection/Inspection.js";

export default {
  name: 'portal-compliance-table-cell-inspection',
  components: {
  },
  props: {
    inspectionData: {type: Object, default: null},
    rowIndex: {type: Number, default: 0},
    showLabels: { type: Boolean, default: false },
  },
  data() {
    return {
      C: new Catelog(),
      MC: new MC(),
      inspection: new Inspection(this.domain),
      showInspectionDetail: false,
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    rowClass: function() {
      if (StringUtils.isEven(this.rowIndex)) {
        return "rc-background-light-thin container";
      }
      return "rc-background-dark-thin container";
    },
  },
  watch: {
    inspectionData() {
      this.inspection = new Inspection(this.domain, this.inspectionData);
    }
  },
  mounted: function () {
    this.inspection = new Inspection(this.domain, this.inspectionData);
  },
  methods: {
    details: function () {
      var params = {
        id: this.inspection.id(),
      }
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_DETAILS,
        params: params,
      });
//      this.$emit('showInspection', this.inspection.id())
    },
    repair: function() {
      var params = {
        id: this.inspection.id(),
      }
      this.$router.push({
        name: ConstUtils.ROUTES.INSPECTION_REPAIRS,
        params: params,
      })
    },
  },
}
</script>