<template>
  <div class="rc-box-col-14"> <!-- root div -->
    <div class="rc-container">

      <div class="rc-row">
        <div class="rc-box-col-12 rc-text-label rc-font-medium"> 
          {{ licenseClass() }} {{ user.first() }} {{ user.last() }} ({{ Strings.trim(employee.roles().find().display()) }})
        </div>
        <div class="rc-box-col-3">
          <div 
            v-if="user.hasLicenseExpired()"
            style="background-color:#000000;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseExpired.value() }}
          </div>
          <div 
            v-else-if="user.willLicenseExpireInWeeks(1)"
            style="background-color:#E90202;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseExpiresInXDays.value([user.licenseDaysLeft()]) }}
          </div>
          <div 
            v-else-if="user.willLicenseExpireInWeeks(4)"
            style="background-color:#F39F42;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseExpiresInXDays.value([user.licenseDaysLeft()]) }}
          </div>
          <div 
            v-else-if="user.willLicenseExpireInWeeks(8)"
            style="background-color:#3F82B6;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseExpiresInXDays.value([user.licenseDaysLeft()]) }}
          </div>
          <div 
            v-else-if="user.isLicenseClassMissing()"
            style="color:#000000;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseClassMissing.value() }}
          </div>
          <div 
            v-else-if="user.isLicenseExpiryMissing()"
            style="color:#000000;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseClassMissing.value() }}
          </div>
          <div 
            v-else
            style="background-color:#009436;color:#FFFFFF;padding: 1px 4px;text-align: -webkit-center;font-size: 15px;border-radius: 5px;">
              {{ MC.User.LicenseGood.value() }}
          </div>
        </div>
      </div>

      <div class="rc-row">
        <div class="rc-box-col-1" />
        <div v-for="index in days" :key="index" class="rc-box-col-1 rc-text-small rc-text-centre rc-text-label">{{ showDate(index) }}</div>
      </div>

      <div class="rc-row" v-if="hasCheckIns()" >
        <div class="rc-box-col-1 rc-font-small rc-text-label">{{ MC.CheckIn.Title.value() }}</div>
        <div v-for="tsIndex in days" :key="'ts' + tsIndex" class="rc-box-col-1 rc-text-value">
          <CheckInComponent 
            :checkInData="findCheckInForDay(employee, tsIndex)"
            :showLabels="showLabels"
            :rowIndex="rowIndex"/>
        </div>
      </div>
      
      <div class="rc-row" v-if="hasTimesheets()">
        <div class="rc-box-col-1 rc-font-small rc-text-label">{{ MC.Timesheet.Title.value() }}</div>
        <div v-for="tsIndex in days" :key="'ts' + tsIndex" class="rc-box-col-1 rc-text-value">
          <TimesheetComponent 
            :timesheetData="findTimesheetForDay(employee, tsIndex)" 
            :rowIndex="rowIndex"
            :columnIndex="tsIndex"
            :showLabels="showLabels"
            :stillLoadingTimesheets="isLoadingTimesheets"
            />
        </div>
      </div>
      
      <div class="rc-row" v-if="hasInspections()">
        <div class="rc-box-col-1 rc-font-small rc-text-label">{{ MC.Inspection.Title.value() }}</div>
        <div v-for="tsIndex in days" :key="'ts' + tsIndex" class="rc-box-col-1 rc-text-value">
            <InspectionsComponent 
              :inspectionsData="findInspectionForDay(employee, tsIndex).data"
              :showLabels="showLabels"
              v-on:showInspection="showInspection"
              :rowIndex="rowIndex"/>
        </div>
      </div>

      <InspectionDetailDialog 
        v-if="showInspectionDetails" 
        :inspectionId="inspectionId"
        v-on:close="hideInspection"/>

    </div>
  </div> <!-- /root div -->
</template>

<script>
import { mapGetters } from 'vuex'

import Catelog         from "@/domain/session/Catalog.js";

//import ConstUtils      from '@/utils/ConstUtils.js';
import StringUtils from '@/utils/StringUtils.js'
import MC from "@/domain/session/MC.js";

import User           from "@/domain/model/user/User.js";
import Employee       from "@/domain/model/employee/Employee.js";
import EmployeeMap    from "@/domain/model/employee/EmployeeMap.js";
import TimesheetMap   from '@/domain/model/timesheet/TimesheetMap.js';
import Inspection     from "@/domain/model/inspection/Inspection.js";
import InspectionMap  from "@/domain/model/inspection/InspectionMap.js";
import CheckInMap     from "@/domain/model/checkin/CheckInMap.js";

import CheckInComponent from "./ComplianceTableCellCheckIn.vue";
import TimesheetComponent from "./ComplianceTableCellTimesheet.vue";
import InspectionsComponent from "./ComplianceTableCellInspections.vue";
import InspectionDetailDialog from './InspectionDetailsDialog.vue';
//import Button         from '@/components/button/Button.vue'
//import SpacerRow      from '@/components/row/SpacerRow.vue';


export default {
  name: 'portal-compliance-table-cell',
  components: {
    CheckInComponent,
    TimesheetComponent,
    InspectionsComponent,
    InspectionDetailDialog,
//    Button,
  },
  props: {
    employeeData: { type: Object, default: null },
    employeeMapData: { type: Object, default: null },
    inspectionMapData: { type: Object, default: null },
    checkInMapData: { type: Object, default: null },
    timesheetMapData: { type: Object, default: null },
    rowIndex: { type: Number, default: 0 },
    stillLoadingTimesheets: { type: Boolean, default: true },
    showLabels: { type: Boolean, default: false },
    startFilterDate: { type: Number, default: 0 },
  },
  data() {
    return {
      C: new Catelog(),
      MC: new MC(),
      user: new User(this.domain),
      employee: new Employee(this.domain),
      employeeMap: new EmployeeMap(this.domain),
      inspectionMap: new InspectionMap(this.domain),
      checkInMap: new CheckInMap(this.domain),
      timesheetMap: new TimesheetMap(this.domain),
      Strings: StringUtils,
      isUpdatingEmployee: false,
      inspectionId: null,
      showInspectionDetails: false,
      days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14],
    }
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'domain',
                   ]),
    isLoadingTimesheets: function() {
      return this.stillLoadingTimesheets;
    },
  },
  watch: {
    employeeMapData() {
      this.employeeMap = new EmployeeMap(this.domain, this.employeeMapData);
    },
    inspectionMapData() {
      this.inspectionMap = new InspectionMap(this.domain, this.inspectionMapData);
    },
    checkInMapData() {
      this.checkInMap = new CheckInMap(this.domain, this.checkInMapData);
    },
    timesheetMapData() {
      this.timesheetMap = new TimesheetMap(this.domain, this.timesheetMapData);
    },
    employeeData() {
      this.employee = new Employee(this.domain, this.employeeData);
      this.user = this.employee.user().find();
    },
  },
  mounted: function () {
    this.employeeMap = new EmployeeMap(this.domain, this.employeeMapData);
    this.inspectionMap = new InspectionMap(this.domain, this.inspectionMapData);
    this.checkInMap = new CheckInMap(this.domain, this.checkinMapData);
    this.timesheetMap = new TimesheetMap(this.domain, this.timesheetMapData);
    this.employee = new Employee(this.domain, this.employeeData);
    this.user = this.employee.user().find();
  },
  methods: {
    findInspectionForDay: function(employee, dayIndex) {
      var day = this.domain.time().at(this.startFilterDate).day();
      var date = day.minus(dayIndex - 1).date();
      var found = this.inspectionMap.findForDay(date, employee);
      if (found.isEmpty()) {
        found.add(new Inspection(this.domain));
      }
      return found;
    },
    
    hasInspections: function() {
      var result = true;
      result = this.domain.session().company().features().find().allowsInspections();
      return result;
    },
    
    findTimesheetForDay: function(employee, dayIndex) {
      var day = this.domain.time().at(this.startFilterDate).day();
      var date = day.minus(dayIndex - 1).date();
      var found = this.timesheetMap.findForDay(date, employee);
      var first = found.first();
      if (first.isNew()) {
        first.withCreatedBy(this.user);
        first.with(this.employee);
        first.withDate(date);
      }
      return first;
    },
    
    hasTimesheets: function() {
      var result = true;
      result = this.domain.session().company().features().find().allowsTimesheets();
      return result;
    },
    
    findCheckInForDay: function(employee, dayIndex) {
      var day = this.domain.time().at(this.startFilterDate).day()
      var date = day.minus(dayIndex - 1).date();
      var found = this.checkInMap.findForDay(date, employee);
      return found.first();
    },
    
    hasCheckIns: function() {
      var result = true;
      result = this.domain.session().company().features().find().allowsCheckIns();
      return result;
    },
    showDate: function(index) {
      if (this.domain) {
        var day = this.domain.time().at(this.startFilterDate);
        return day.minus(index - 1).displayAs_MM_DD();
      }
      return "";
    },
    exclude: function() {
      this.$emit('exclude', this.employeeMap.data);
    },
    include: function() {
      this.$emit('include', this.employeeMap.data);
    },
    
    showInspection: function(inspectionId) {
      this.inspectionId = inspectionId;
      if (!this.showInspectionDetails) {
        this.showInspectionDetails = true;
      }
    },
    hideInspection: function() {
      this.showInspectionDetails = false;
    },
    licenseClass: function() {
      var _class = this.user.licenseClass();
      if (!StringUtils.isEmpty(_class)) {
        _class = "(" + _class + ")";
      }
      return _class;
    },
    licenseDaysLeft: function() {
      var days = this.user.licenseDaysLeft();
      if (days < 0) {
        return "No license";
      }
      return "License Expires In: " + days + " days"
    },
  },
}
</script>